
table.order-detail-list {

  font-size: 0.8em;

  tbody {
    font-size: 13px;
    font-weight: 500;

    tr {

      &:hover {
        cursor: pointer;
      }
    }
  }

  td {
    padding: 5px !important;
    word-break: break-word;
    min-width: 80px;

    button {
      font-size: 12px;
    }
  }

  .order-cid {
    font-weight: bold;
  }

  .order-products {

    ul {
      width: 180px;
      padding-left: 0;
      margin: 0;
      list-style: none;

      li {
        font-weight: 500;
        font-size: 13px;
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }

  .order-date {
  }

  .order-status {
    text-transform: capitalize;
  }

  .order-address {
    min-width: 150px;
  }
}

.back-button {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 30px;
  cursor: pointer;
}

.responses-card {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
}

.quiz-report {
  width: 100%;
  min-height: 600px;
}
