.address-container {

  .address-heading {
    font-size: 0.9em;
    color: #545454;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .address {
    font-weight: 600;
    font-size: 0.8em;
    margin-bottom: 0;
    color: #9f9c9c;
  }
}