.incoming-call-detail-form {
  padding: 0;

  .incoming-call-detail-form-header-small, .incoming-call-detail-form-header {
    display: flex;
    color: white;
    background-color: #800;

    a {
      font-weight: bold;
      color: white;
      text-decoration: underline;
    }
  }

  .incoming-call-detail-form-header-small {
    padding: 5px 10px;
    display: flex;

    .incoming-call-detail-form-header-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
      flex: 1;
    }
    .incoming-call-detail-form-user-image {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin: 2px;
      border: 2px solid white;
    }

    .btn {
      padding: 0 10px;
    }
  }

  .incoming-call-detail-form-header {
    padding: 15px;

    .incoming-call-detail-form-header-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
    }
    .incoming-call-detail-form-user-image {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      margin: 10px;
      border: 5px solid white;
    }
  }

  .incoming-call-detail-form-body {
    padding: 5px 15px 15px;
    .btn {
      padding: 8px 15px;
      font-size: 1.2em;
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
  }
  .incoming-call-detail-form-duration-total {
    font-size: 0.8em;
    text-align: center;
    margin-bottom: 5px;
  }
  .incoming-call-detail-form-duration {
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
