
.call-now-button {
  border-radius: 15px !important;
  background-color: white !important;
  border-color: white;
  border: none;
  color: #d0021b;
  box-shadow: none !important;
  font-size: 0.8em !important;
  font-weight: 600;
  padding: 2px 10px;

  &:hover, &:active {
    background-color: white !important;
    color: #d0021b !important;
    border-color: #d0021b;
  }
}
