
table.order-detail-list {

  font-size: 0.8em;

  tbody {
    font-size: 13px;
    font-weight: 500;

    tr {

      &:hover {
        cursor: pointer;
      }
    }
  }

  td {
    padding: 5px !important;
    word-break: break-word;
    min-width: 80px;

    button {
      font-size: 12px;
    }
  }

  .order-cid {
    font-weight: bold;
  }

  .order-products {

    ul {
      width: 180px;
      padding-left: 0;
      margin: 0;
      list-style: none;

      li {
        font-weight: 500;
        font-size: 13px;
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }

  .order-date {
  }

  .order-status {
    text-transform: capitalize;
  }

  .order-address {
    min-width: 150px;
  }
}

.back-button {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 30px;
  cursor: pointer;
}

.call-detail-form-option {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 3px;
}

.call-detail-form-label {
  font-size: 14px;
  margin-bottom: 0;
}

.call-detail-form-options {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  input {
    display: inline-block;
  }
  label {
    display: inline-block;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 12px;
    margin-bottom: 0;
  }
}
