


.incoming-call-toast {
  position: fixed;
  bottom: 0;
  width: 300px;
  //padding: 5px 10px;
  padding: 0;
  z-index: 10000;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  font-size: 12px;

  .call-detail-form h6 {
    font-size: 1em;
  }
}

.missed-call-toast {
  padding: 10px;
}

.incoming-call-toast-1 {
  right: 20px;
  width: 300px;
}
.incoming-call-toast-2 {
  right: 350px;
}
.incoming-call-toast-3 {
  right: 680px;
}
.incoming-call-toast-4 {
  right: 1010px;
}

@media only screen and (max-width: 767px)  {
  .incoming-call-toast-1 {
    right: 20px;
    width: 300px;
  }
  .incoming-call-toast-2 {
    right: 20px;
    bottom: 30px;
  }
  .incoming-call-toast-3 {
    right: 20px;
    bottom: 60px;
  }
  .incoming-call-toast-4 {
    right: 20px;
    bottom: 90px;
  }
}

.incoming-call-toast-collapsed {
  width: 300px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #800;
  color: white;
  display: flex;
  justify-content: space-between;
}

.incoming-call-toast__close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 10001;
}

.incoming-call-toast__open-button {
  position: absolute;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
  z-index: 10001;
}

.incoming-call-toast-list {
  padding-left: 0;
  margin-bottom: 30px;
}

.incoming-call-toast-listitem {
  padding: 10px 0;
  list-style: none;
  border-bottom: 1px solid #f5f5f5;
  font-size: 0.8em;
}

.connection-status {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #800;
  color: white;
  padding: 5px 10px;
  font-size: 0.8em;
  text-align: center;
  z-index: 10000;
}
