
.order-detail-text-container {
  margin-right: 25px;
  position: relative;
  display: inline-block;

  .order-detail-text-heading {
    color: #545454;
    font-size: 0.95em;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
  }

  .copy-button {
    position: absolute;
    font-size: 0.6em;
    padding: 2px 5px !important;
    top: 0;
    right: 0;
  }

  .order-detail-text {
    color: #555555;
    font-size: 0.9em;
    margin: 0;
    white-space: nowrap;
  }
}
