.order-create-modal-table-container {
  //overflow-x: scroll;
  //overflow-y: visible;
  width: 100%;
}
.order-create-modal-table {

  td {
    padding: 4px 3px !important;
  }
}
