.order-detail-top-section {

  .button-container {
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;

    .order-detail-button {
      margin-right: 10px;
      max-width: 110px;
      width: 110px;
      font-size: 0.75em;
      margin-bottom: 5px;
    }
  }
}