.date-picker-rage {

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;

      .react-datepicker__close-icon::after {
        background-color: #e2e1e7;
        color: #696969;
        margin: 0 auto;
        font-size: 20px;
        bottom: 50%;
        transform: translate(0, 50%);
        width: auto;
        height: auto;
      }
    }
  }
}