@import "./constants";

body {
  font-family: 'Spartan', sans-serif;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.muted {
  color: #939aa0;
}

.min-vh-100 {
  min-height: 100vh;
}

.toast-container {
  .Toastify__toast {
    background-color: black;
    color: white;
  }

  .Toastify__close-button {
    color: white;
    opacity: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.custom-form-control {
  border-radius: 0.25em !important;
  background-color: #e2e1e7 !important;
  font-size: 0.8em;
  box-shadow: none !important;
  border-color: #e2e1e7 !important;

  .css-yk16xz-control {
    background-color: #e2e1e7 !important;
    border: none;
  }
}

.custom-form-label {
  font-size: 0.8em;
  color: #696969;
  margin-bottom: 0;
  font-weight: 600;
}

.custom-button {
  border-radius: 0.25em !important;
  background-color: #e2e1e7;
  border-color: #e2e1e7;
  color: #696969;
  box-shadow: none !important;
  font-size: 0.8em;
  font-weight: 600;

  &:hover, &:active {
    background-color: #474545 !important;
    color: white;
  }
}

.disabled {
  cursor: not-allowed !important;
}


.btn {
  font-size: 0.8em;
  padding: 5px 10px;
  line-height: normal;

  &.btn-danger {
    background-color: #e30a17;
    border-color: #e30a17;
  }

  &.btn-warning {
    background-color: #f5a623;
    border-color: #f5a623;
    color: white !important;
  }

  &.btn-info {
    background-color: #2cc8c1;
    border-color: #2cc8c1;
  }

  &.btn-primary {
    background-color: #30a8ff;
    border-color: #30a8ff;
  }

  &.btn-dark {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    color: #696969 !important;

    &:active {
      color: white !important;
    }
  }
}

.heading {
  font-size: 0.9em;
  color: #545454;
  font-weight: 600;
  margin-bottom: 5px;
}

.text {
  font-size: 0.8em;
  color: #545454;
}

.audio-input {
  height: 30px;
  max-width: 200px;
}

.table {

  tbody {
    font-size: 13px;
    font-weight: 500;
    border: none;

    tr {
      background-color: white;
    }

    td {
      border: none;
    }
  }

  thead {
    background-color: #eeeeee;
    color: #818181;
    border: none;

    th {
      padding: 5px;
      border: none;
      font-weight: normal;
    }
  }
}

.modal-header {
  color: #696969;
  font-weight: 600;
  font-size: 18px;
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.text-black {
  color: black !important;
}

.react-datepicker {

  .react-datepicker__navigation--next--with-time {
    &:not(.react-datepicker__navigation--next--with-today-button) {
      right: 110px !important;
    }
  }

  .react-datepicker__time-container {
    width: 100px !important;

    .react-datepicker__time-box {
      width: 100px !important;
    }

    li.react-datepicker__time-list-item {
      height: auto !important;
    }
  }
}

.text-unselectable {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

.strike {
  text-decoration: line-through;
}
