$light-blue: #DCEAFF;

.sidebar {
  background-color: #d0021b;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -180px;
  width: 220px;
  z-index: 5;
  transition: left 300ms ease;
  overflow-y: auto;
  //@media only screen and (max-width: 991px) {
  //  left: -200px;
  //}
}

.sidebar.active {
  left: 0;
  transition: left 300ms ease;

  .sidebar-item {
    flex-direction: row;

    .side-item-text {
      visibility: visible;
    }
  }

  .sidebar-header {
    .logo {
      display: inline-block;
    }
  }
}

.sidebar-toggle {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  padding: 5px;
  color: $light-blue;
  font-weight: 700;
  font-size: 20px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: rotate(0deg);
  transition: box-shadow 300ms ease, transform 300ms ease;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
    transition: box-shadow 300ms ease;
  }
}

.sidebar-toggle.active {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.sidebar-items {
  padding: 0;
  margin: 0;
}

.sidebar-item-container {
  ul {
    padding: 0;
  }
  .sidebar-item {
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 4px solid transparent;
  }
  .sidebar-item:first-child {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0);
    border-left: 4px solid transparent;
  }
  .sidebar-item.active {
    border-left: 4px solid $light-blue;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .sidebar-item:first-child.active {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 4px solid transparent;
  }
}

.sidebar-item {
  color: white;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  user-select: none;
  border-left: 4px solid transparent;

  .side-item-text {
    padding-left: 10px;
    display: flex;
    flex: 1;
    visibility: hidden;
  }

  .material-icons {
    font-size: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sidebar-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 4px solid $light-blue;
  font-weight: 700;
}

.sidebar-header {
  padding: 20px 20px 10px 20px;
  color: white;
  font-weight: 900;
  font-size: 24px;

  .logo {
    width: 100%;
    display: none;
  }
}

.sidebar-container {
  padding-left: 50px;
  transition: padding-left 300ms ease;
}

.sidebar-container.active {
  @media only screen and (min-width: 991px) {
    padding-left: 220px;
    transition: padding-left 300ms ease;
  }
}

.sidebar-shift-container {
  font-size: 0.9em;
  //color: white;
  max-width: 100%;
  padding: 5px;
  background-color: white;
  margin: 5px 5px 20px 5px;
  border-radius: 5px;

  table {
    padding: 0;
    border: 2px solid #999;
    border-radius: 10px;
    margin-bottom: 5px;
    width: 100%;
     th, td {
       padding: 5px !important;
       text-align: center;
       font-size: 0.8em;
       vertical-align: middle;
     }
  }

}

.shift-inactive-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(0,0,0,0.8);
  padding: 100px;
  text-align: center;
  p {
    color: white;
    font-size: 2em;
  }
  button {
    padding: 20px;
    font-size: 1.5em;
  }
}
