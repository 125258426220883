.orders-color-coding-container {
  font-size: 0.7em;

  .orders-color-coding {
    display: flex;
    justify-content: space-between;

    .color-code {
      margin: 0;
      display: inline-flex;
      align-items: center;

      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border-radius: 0.25em;
      }
    }
  }
}