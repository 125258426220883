
table {
  tbody {
    font-size: 14px;
    font-weight: 500;
  }

  td {
    padding: 10px !important;
  }

  .order-cid {
    font-weight: bold;
  }

  .order-products {
    ul {
      width: 200px;
      padding-left: 0;
      margin: 0;
      list-style: none;

      li {
        font-weight: 500;
        font-size: 13px;
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }

  .order-date {
  }

  .order-status {
    text-transform: capitalize;
  }

  .order-address {
  }
}