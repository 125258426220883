
table.order-list {
  font-size: 0.8em;
  margin-bottom: 50px;

  td {
    padding: 5px !important;
    word-break: break-word;
    min-width: 100px;
    vertical-align: middle;
    color: #000;
    border: 0;

    button {
      font-size: 12px;
      white-space: nowrap;
    }
    &.sno {
      min-width: 40px;
    }
  }

  .order-products {

    ul {
      width: 150px;
      padding-left: 0;
      margin: 0;
      list-style: none;

      li {
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }
}
