.address-form {


  .form-control {
    border-color: black;
    height: auto;
    padding: 3px 10px;
    line-height: normal;
    font-size: 0.8em;
    color: #4a4a4a;
    font-weight: 600;
  }

  .form-group {
    margin-bottom: 1px;
  }

  .form-label {
    font-size: 0.8em;
    margin-bottom: 0;
  }

  ::placeholder {
    color: #cacaca;
    font-weight: 400;
  }
}